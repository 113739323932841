<template>
    <v-card class="fill-height rounded-b-0 elevation-0 py-0 mx-auto"
            :color="background"
            :dark="schema === 'dark'"
            :style="{width: this.width}"
    >
        <v-breadcrumbs :items="crumbs" v-if="crumbs && showCrumbs">
        </v-breadcrumbs>
    </v-card>
</template>

<script>
    import getLoader from "../../utils/customizeOptions";
    import eventBus from "../../utils/eventBus";

    export default {
        name: "BreadCrumbs",
        props: ['crumbs'],
        data: () => {
              return {
                  background: 'transparent',
                  activeColor: 'blue',
                  inactiveColor: 'gray',
                  schema: null,
                  showCrumbs: true,
                  width: '100vw'
              }
        },
        computed: {
            isMobile: function() {
                return window.innerWidth < 960;
            }
        },
        async beforeMount() {
            await getLoader().loadOptions();

            this.schema = getLoader().getOption(['Common', 'Schema']);
            this.showCrumbs = getLoader().getBool(['Common', 'BreadCrumbs'], this.isMobile);
            this.background = getLoader().getOption(['Common', 'BreadCrumbs', 'Background'], this.isMobile) || this.background;
            this.width = getLoader().getOption(['Common', 'BreadCrumbs', 'Width'], this.isMobile) || this.width;
            console.log('show crumbs', this.showCrumbs);
            console.log('background crumbs', this.background);
            console.log('schema crumbs', this.schema);
        },
        watch: {
            crumbs: function() {
                eventBus.$emit('navigation-changed', this.crumbs);
            }
        }
    }
</script>

<style scoped>

</style>
