<template>
    <vueper-slides
            class="no-shadow px-16"
            :bullets="true"
            :arrows="true"
            :slide-ratio="ratio"
            slide-multiple
            :gap="1"
            autoplay
            pauseOnTouch
            :dragging-distance="70"
            prevent-y-scroll
            :visible-slides="3"
    >
        <vueper-slide v-for="(image, index) of images"
                      :key="`image-${index}-${image}`"
                      contain
                      :link="linkTo(image)"
                      :image="getStatic(image)"
        >
        </vueper-slide>
    </vueper-slides>
</template>
<script>
    import {getStatic} from "../../../utils/settings";
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css';

    export default {
        name: 'SliderWithThreeImages',
        components: { VueperSlides, VueperSlide },
        props: {
            content: {}
        },
        data: () => {
            return {
                images: [],
                ratio: 1/10,
            }
        },
        methods: {
            getStatic,
            linkTo(text) {
                text;
            }
        },
        mounted() {
            this.images = this.content.Content;

            try {
                const parsed = JSON.parse(this.content.Description);
                console.log('parsed: ', parsed)

                this.ratio = parseFloat(parsed.ratio);
                console.log('ratio: ', this.ratio)
            } catch (e) {
                console.error(e);
            }
        }
    }
</script>
